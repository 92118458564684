/******************************************************************************************************************************************************************************
 *
 *                          Solution: Smart-Recruitment
 *                          Description: Handles the homepage application component with the form for recruiters to input the job title and description
 *                          Created Date: January 22nd, 2025
 *                          Created By: Areeb Khan
 *                          Last Updated Date: February 4th, 2025
 *                          Last Updated By: Areeb Khan
 *                          Version: 1.0
 *
 ********************************************************************************************************************************************************************************/
//Imported Libraries
import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
// import { useNavigate } from 'react-router-dom';
import JobForm from './JobForm.tsx';
import TechRequirements from './TechRequirements.tsx';
import BooleanString from './BooleanString.tsx';
import './smartrecruitment.css';
import ExperienceSection from './Experience.tsx';
import { loginRequest } from "../authConfigur.ts";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import { loginRequest } from '../authConfigur'
// import { callMsGraph } from '../graph.ts';

// Interface for the experience object
interface ExperienceDetails{ // Interface for the experience object
  from: string;
  to: string;
}

// Interface for the technology requirements object
interface TechnologyRequirement{ // Interface for the technology requirements object
  BOOLEANNAMEVALUEPAIRID?: number;
  BOOLEANNAME: string;
  BOOLEANVALUE: string;
  weightage?: string;
  isEditable?: boolean;
  index: number;
}

// Interface for the boolean pair object
interface BooleanPair{ // Interface for the boolean pair object
  BOOLEANNAMEVALUEPAIRID: number;
  BOOLEANNAME: string;
  BOOLEANVALUE: string;
}

const App: React.FC = () => {
  const [jobTitle, setJobTitle] = useState(''); // State for the job title
  const [description, setDescription] = useState(''); // State for the job description
  const [experience, setExperience] = useState<ExperienceDetails>({ from: '', to: '' }); // State for the experience
  const [parsedKeyTitle, setParsedKeyTitle] = useState(''); // State for the parsed key title
  const [technologyRequirements, setTechnologyRequirements] = useState<TechnologyRequirement[]>([]); // State for the technology requirements
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false); // State for the form submission status
  const [booleanPairs, setBooleanPairs] = useState<BooleanPair[]>([]); // State for the boolean pairs
  const [userInfo, setUserInfo] = useState<{ firstName: string, lastName: string, email: string } | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [jobCode, setJobCode] = useState<string | null>(null)
  const [booleanPhrase, setBooleanPhrase] = useState<string>('')
  const [weightages, setWeightages] = useState<{value: number, isChanged: boolean}[]>([]);

  const { instance, accounts } = useMsal();
  // const navigate = useNavigate();

  // Mapping of job titles to experience levels
  const keytitlesMapping = {
    Junior: { from: '4', to: '5' },
    Intermediate: { from: '6', to: '9' },
    Senior: { from: '10', to: '25' },
  };

  const groupIdToRoleMap: { [key: string]: string } = {
    [process.env.REACT_APP_GROUP_BOOLEAN_MODIFIER_ID as string]: "Boolean_Modifier",
    [process.env.REACT_APP_GROUP_BOOLEAN_READER_ID as string]: "Boolean_Reader"
  };

  // useEffect(() => {
  //   const handleRedirect = async () => {
  //     try {
  //       await instance.initialize();
  //       const response = await instance.handleRedirectPromise();
  //       if (response) {
  //         instance.setActiveAccount(response.account);
  //       } else {
  //         const activeAccount = instance.getActiveAccount();
  //         if (!activeAccount && accounts.length > 0) {
  //           instance.setActiveAccount(accounts[0]);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error handling redirect", error);
  //     }
  //   };

  //   handleRedirect();
  // }, [accounts, instance]);
  
  useEffect(() => { // Renders the fetchBooleanPairs function
    const fetchBooleanPairs = async () => { // Function to fetch the boolean pairs from the backend server("http://localhost:3000/booleanPairs")
      try {
        const activeAccount = instance.getActiveAccount() // Gets the active account
        if(!activeAccount){
          throw new Error(`${process.env.REACT_APP_ERROR_MESSAGE_NOACTIVEACCOUNT}`)
        }
        console.log(`${process.env.REACT_APP_MESSAGE_FETCHBOOLEANPAIRS}`)
        
        await instance.initialize(); // Initializes the MSAL instance to avoid BrowserAuthError
        
        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: activeAccount || undefined
        }) 
        const accessToken = tokenResponse.accessToken // Assigns the access token to a variable
        const idToken = tokenResponse.idToken
        console.log(`${process.env.REACT_APP_MESSAGE_ACCESSTOKEN}`);
        console.log(`${process.env.REACT_APP_MESSAGE_IDTOKEN}`)
        
        const idTokenClaims = tokenResponse.idTokenClaims as any;
        const firstName = idTokenClaims.given_name || idTokenClaims.name?.split(' ')[0];
        const lastName = idTokenClaims.family_name || idTokenClaims.name?.split(' ').slice(1).join(' ');
        const email = idTokenClaims.email || idTokenClaims.preferred_username;

        // if(idTokenClaims.groups){
        //   console.log("Group IDs are: ", idTokenClaims.groups);
        // } else{
        //   console.log("No groups were found")
        // }

        setUserInfo({ firstName, lastName, email });

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/booleanPairs`, { // Fetches the boolean pairs from the backend server
          headers: {
            Authorization: `Bearer ${accessToken}`, // Sets the authorization header with the access token
            Authentication: `Bearer ${idToken}`
          },
        })

        const data = await response.json(); // Parses the JSON data and assigned to a variable
        console.log("Boolean pairs fetched successfully")
        setBooleanPairs(data); // Sets the parsed data to the state
      } catch (error) {
        console.error("Error fetching boolean pairs", error);
      }
    };
    if(instance.getActiveAccount()){
      fetchBooleanPairs(); // Calls the function to fetch the boolean pairs
    }
  }, [instance]);

  // useEffect(() => {
  //   if (accounts.length > 0) {
  //       instance.acquireTokenSilent({
  //           ...loginRequest,
  //           account: accounts[0]
  //       }).then((response) => {
  //           callMsGraph(response.accessToken).then(data => {
  //               // console.log("User data from Graph API:", data); 
  //               if (data.value && data.value.length > 0) {
  //                   data.value.forEach((group, index) => {
  //                       // console.log(`Group ${index}:`, group);
  //                       // console.log(`Group ${index} properties:`, Object.keys(group));
  //                   });
  //                   const roles = data.value.map(group => groupIdToRoleMap[group.id] || "Unnamed Group"); 
  //                   setRoles(roles);
  //                   console.log(`Roles retrieved: ${roles}`);
  //               } else {
  //                   console.log("No groups found in the response.");
  //               }
  //           });
  //       }).catch((error) => {
  //           if (error instanceof InteractionRequiredAuthError) {
  //               instance.acquireTokenPopup({
  //                   ...loginRequest,
  //                   account: accounts[0]
  //               }).then((response) => {
  //                   callMsGraph(response.accessToken).then(data => {
  //                       console.log("User data from Graph API:", data); 
  //                       if (data.value && data.value.length > 0) {
  //                           data.value.forEach((group, index) => {
  //                               // console.log(`Group ${index}:`, group);
  //                               // console.log(`Group ${index} properties:`, Object.keys(group));
  //                           });
  //                           const roles = data.value.map(group => groupIdToRoleMap[group.id] || "Unnamed Group"); 
  //                           setRoles(roles);
  //                           console.log(`Roles retrieved: ${roles}`);
  //                       } else {
  //                           console.log("No groups found in the response.");
  //                       }
  //                   });
  //               });
  //           }
  //       });
  //   }
  // }, [accounts, instance]);

//   useEffect(() => {
//     if (accounts.length > 0) {
//         instance.acquireTokenSilent({
//             ...loginRequest,
//             account: accounts[0]
//         }).then((response) => {
//             callMsGraph(response.accessToken).then(data => {
//                 console.log("User data from Graph API:", data); // Log the user data
//                 if (data.value && data.value.length > 0) {
//                     data.value.forEach((group, index) => {
//                         console.log(`Group ${index}:`, group);
//                         console.log(`Group ${index} properties:`, Object.keys(group));
//                     });
//                     const roles = data.value.map(group => group.displayName || "Unnamed Group"); // Extract the group names
//                     setRoles(roles);
//                     console.log(`Roles retrieved: ${roles}`);
//                 } else {
//                     console.log("No groups found in the response.");
//                 }
//             });
//         }).catch((error) => {
//             if (error instanceof InteractionRequiredAuthError) {
//                 instance.acquireTokenPopup({
//                     ...loginRequest,
//                     account: accounts[0]
//                 }).then((response) => {
//                     callMsGraph(response.accessToken).then(data => {
//                         console.log("User data from Graph API:", data); // Log the user data
//                         if (data.value && data.value.length > 0) {
//                             data.value.forEach((group, index) => {
//                                 console.log(`Group ${index}:`, group);
//                                 console.log(`Group ${index} properties:`, Object.keys(group));
//                             });
//                             const roles = data.value.map(group => group.displayName || "Unnamed Group"); // Extract the group names
//                             setRoles(roles);
//                             console.log(`Roles retrieved: ${roles}`);
//                         } else {
//                             console.log("No groups found in the response.");
//                         }
//                     });
//                 });
//             }
//         });
//     }
// }, [accounts, instance])

  const fetchBooleanPairsAgain = async () => {
    try {
      const activeAccount = instance.getActiveAccount();
      if (!activeAccount) {
        throw new Error(`${process.env.REACT_APP_ERROR_MESSAGE_NOACTIVEACCOUNT}`);
      }
      console.log(`${process.env.REACT_APP_MESSAGE_FETCHBOOLEANPAIRS}`);
      
      await instance.initialize();
      
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount || undefined
      });
      
      const accessToken = tokenResponse.accessToken // Assigns the access token to a variable
      const idToken = tokenResponse.idToken
      console.log(`${process.env.REACT_APP_MESSAGE_ACCESSTOKEN}`);
      console.log(`${process.env.REACT_APP_MESSAGE_IDTOKEN}`)

      const idTokenClaims = tokenResponse.idTokenClaims as any;
      const firstName = idTokenClaims.given_name || idTokenClaims.name?.split(' ')[0];
      const lastName = idTokenClaims.family_name || idTokenClaims.name?.split(' ').slice(1).join(' ');
      const email = idTokenClaims.email || idTokenClaims.preferred_username;

      // if(idTokenClaims.groups){
      //   console.log("Group IDs are: ", idTokenClaims.groups);
      // } else{
      //   console.log("No groups were found")
      // }

      setUserInfo({ firstName, lastName, email });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/booleanPairs`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Authentication: `Bearer ${idToken}`
        },
      });
      const data = await response.json();
      console.log("Boolean pairs fetched successfully");
      setBooleanPairs(data);
    } catch (error) {
      console.error("Error fetching boolean pairs", error);
    }
  };

  const parseKeywords = (description: string) => { // Function to parse the keywords from the job description
    return booleanPairs.filter((pair) => {
      // Creates a regular expression from the boolean name and checks if it is present in the description
      // A regular expression is a sequence of characters that define a search pattern
      const regex = new RegExp(`\\b${pair.BOOLEANNAME.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'i'); // Utilizes "//b"(boundary anchor) to ensure that the match is a whole word
      return regex.test(description); // Returns the booleannames that match the description keywords
      }).map(pair => ({
        BOOLEANNAMEVALUEPAIRID: pair.BOOLEANNAMEVALUEPAIRID, // Maps the pairs to the boolean name value pair id
        BOOLEANNAME: pair.BOOLEANNAME, // Maps the pairs to the boolean name
        BOOLEANVALUE: pair.BOOLEANVALUE.split(',').join(', '), // Maps the pairs to the boolean value and splits the values by commas and flattens the multiple arrays into a single one
        isEditable: false // Flag for whether the boolean name/value is editable or not
      }));
  };
  // Function to parse the job title
  const parseJobTitle = (jobTitle: string) => {
    return Object.keys(keytitlesMapping).find((key) => // Returns the key title that matches the job title
      jobTitle.toLowerCase().includes(key.toLowerCase())
    );
  };

  const parseJobCode = (jobTitle: string): string | null => {
    const jobCodeRegex = /^([A-Z]{2}\d{5})/;
    const match = jobTitle.match(jobCodeRegex);
    return match ? match[1] : null;
  };

  // Function to handle the form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Parse experience level from the job title
    handleSubmitReset();
    await timeout(200);
    const code = parseJobCode(jobTitle)
    setJobCode(code)
    console.log("The job code is: ", jobCode);
    const matchedKeyTitle = parseJobTitle(jobTitle) as keyof typeof keytitlesMapping; // Assigns variable to the matched key title
    if (matchedKeyTitle) {
      const experienceRange = keytitlesMapping[matchedKeyTitle]; // Assigns variable to the experience range
      setExperience({ from: experienceRange.from, to: experienceRange.to }); // Sets the experience range
      setParsedKeyTitle(matchedKeyTitle); // Sets the parsed key title
    } else {
      setExperience({ from: '', to: '' }); // Resets the experience range
      setParsedKeyTitle(''); // Resets the parsed key title
    }
    // Parse technology requirements from the job description
    const parsedKeywords = parseKeywords(description); // Assigns variable to the parsed keywords
    setTechnologyRequirements(parsedKeywords.map((keyword, index) => ({ ...keyword, index }))); // Sets the parsed keywords for the technology requirements
    setIsSubmitted(true); // Sets the form submission status to true(submit has been clicked)
  };

  const handleReorder = (newTechRequirements: TechnologyRequirement[]) => {
    setTechnologyRequirements(newTechRequirements)
  }
  // Function to add a technology requirement
  const handleAddTechnology = () => {
    setTechnologyRequirements([...technologyRequirements, { BOOLEANNAME: '', BOOLEANVALUE: '', isEditable: true, index: technologyRequirements.length }]);
  }

  // Function to remove a technology requirement
  const handleRemoveTechnology = (index: number) => {
    setTechnologyRequirements(prevState => prevState.filter((_, i) => i !== index));
  }

  // Function to handle the technology change
  const handleTechnologyChange = (index: number, key: string, newValue: string | number) => {
    setTechnologyRequirements(prevState => {
      const updatedRequirements = [...prevState];
      updatedRequirements[index] = {
        ...updatedRequirements[index],
        [key]: newValue,
        isEditable: key === 'BOOLEANNAME' ? true : updatedRequirements[index].isEditable
      };
      return updatedRequirements;
    });
  };

  // const checkLogoutStatus = async () => {
  //   try{
  //     const activeAccount = instance.getActiveAccount();
  //     await instance.initialize();
  //     if (!activeAccount) {
  //       console.error("No active account, please log in.");
  //       navigate('/'); // Redirect to homepage if no active account
  //       return;
  //     }
  //     const tokenResponse = await instance.acquireTokenSilent({
  //       ...loginRequest,
  //       account: activeAccount || undefined
  //     });
  //     const token = tokenResponse.idToken;
  //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/logout`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ token })
  //     });
  //     if (response.status === 200) {
  //         console.log('User is still logged in');
  //         // Do not navigate to homepage
  //     } else {
  //         console.log('User has logged out');
  //         navigate('/'); // Redirect to homepage only after successful logout
  //     }
  //   } catch (error) {
  //     console.error('Error verifying token', error);
  //   }      
  // }

  // const handleLogout = () => {
  //   const logoutEvent = new Event('logout');
  //   window.dispatchEvent(logoutEvent);
    
  //   instance.logoutPopup().then(() => {
  //     if(userInfo){
  //       console.log(`User logged out: ${userInfo.firstName} ${userInfo.lastName}, Email: ${userInfo.email}`);
  //     } else{
  //       console.log("User logged out")
  //     }
  //     checkLogoutStatus();
  //   }) .catch((error) => {
  //       console.error("Logout Error or Popup closed without logging out", error);
  //   });
  // }

  const handleLogout = () => {
    const logoutEvent = new Event('logout');
    window.dispatchEvent(logoutEvent);

    let logoutSuccessful = false;

    instance.logoutPopup().then(() => {
        const activeAccount = instance.getActiveAccount();
        if (activeAccount) {
            console.log("Logout popup closed without logging out");
            return; 
        }
        logoutSuccessful = true;
        if (userInfo) {
            console.log(`User logged out: ${userInfo.firstName} ${userInfo.lastName}, Email: ${userInfo.email}`);
        } else {
            console.log("User logged out");
        }
        // navigate('/');
        window.location.href = `${window.location.origin}`; 
    }).catch((error) => {
        console.error("Logout Error or Popup closed without logging out", error);
    });
  };

  // const handleLogout = () => {
  //   const logoutEvent = new Event('logout');
  //   window.dispatchEvent(logoutEvent);

  //   const logoutRequest = {
  //       postLogoutRedirectUri: window.location.origin, // Redirect to the home page after logout
  //   };

  //   instance.logoutRedirect(logoutRequest).then(() => {
  //       console.log("User logged out");
  //       navigate('/'); // Navigate to the home page after logout
  //   }).catch((error) => {
  //       console.error("Logout Error", error);
  //   });
  // };

  const handleReset = () => { // Function to reset the web application
    // Reset all the states
    setJobTitle('');
    setDescription('');
    setParsedKeyTitle('');
    setTechnologyRequirements([]);
    setIsSubmitted(false);
    fetchBooleanPairsAgain();
  }
  
  const timeout = (delay: number) => {
    return new Promise(res => setTimeout(res, delay));
  };

  const handleSubmitReset = () => {
    setTechnologyRequirements([])
    setIsSubmitted(false)
  }

  const handleExperienceChange = (key: keyof ExperienceDetails, value: string) => {
    setExperience(prev => ({ ...prev, [key]: value }));
  };

  return (
    <div>
    {/* Navigation bar */}
        {/* <Navigation /> */}
      {/* Home page container */}
      <div className="app-container">
        <div className='app-title'>
          <h1 className='title'>Smart Recruitment</h1>
          <button onClick={handleLogout} className='logout-button'>Logout</button>
        </div>

        {/* Job title and description form */}
        <JobForm
          jobTitle={jobTitle}
          description={description}
          setJobTitle={setJobTitle}
          setDescription={setDescription}
          handleSubmit={handleSubmit}
          onReset={handleReset}
          fetchBooleanPairsAgain={fetchBooleanPairsAgain}
          // calculateInitialWeightages={calculateInitialWeightages}
        />

        {isSubmitted && (
          <div className="submitted-container">

            {/* Experience component */}
            <ExperienceSection
              experience={experience}
              handleExperienceChange={handleExperienceChange}
            />
            
            {/* Technology requirements component */}
            <TechRequirements
              technologyRequirements={technologyRequirements}
              handleTechnologyChange={handleTechnologyChange}
              handleAddTechnology={handleAddTechnology}
              handleRemoveTechnology={handleRemoveTechnology}
              fetchBooleanPairsAgain={fetchBooleanPairsAgain}
              handleReorder={handleReorder}
              // roles={roles}
              // weightages = {weightages}
              // setWeightages = {setWeightages}
            />
            
            {/* Boolean string component */}
            <BooleanString 
              technologyRequirements={technologyRequirements}
              // booleanPhrase={booleanPhrase}
              // setBooleanPhrase={setBooleanPhrase}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default App;
