import React, {ChangeEvent} from 'react';
import './smartrecruitment.css';

interface Experience {
    from: string;
    to: string;
};

interface ExperienceProps {
    experience: Experience;
    handleExperienceChange: (field: keyof Experience, value: string) => void;
};

const ExperienceSection: React.FC<ExperienceProps> = ({experience, handleExperienceChange}) => {
    return (
        <div className="experience-container">
            <label className="experience-label">Experience Level Requirement</label>
            <div className="experience-grid">
                <div className="experience-input-container">
                    <label className="experience-input-label">From</label>
                    <input
                        type="number"
                        value={experience.from}
                        placeholder="Years"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleExperienceChange('from', e.target.value)}
                        className="experience-input"
                    />
                </div>
                <div className="experience-input-container">
                    <label className="experience-input-label">To</label>
                    <input
                        type="number"
                        value={experience.to}
                        placeholder="Years"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleExperienceChange('to', e.target.value)}
                        className="experience-input"
                    />
                </div>
            </div>
        </div>
    );
};

export default ExperienceSection;