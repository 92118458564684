/******************************************************************************************************************************************************************************
 *
 *                          Solution: Smart-Recruitment
 *                          Description: Handles the authentication  for the application using Microsoft Azure Active Directory(entraID)
 *                          Created Date: January 22nd, 2025
 *                          Created By: Areeb Khan
 *                          Last Updated Date: February 4, 2025
 *                          Last Updated By: Areeb Khan
 *                          Version: 1.0
 *
 ********************************************************************************************************************************************************************************/

//Imported Libraries
import React, {useState, useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfigur.ts";
// import { loginRequest } from "./authConfigur";
import './layout.css';

const Auth: React.FC = () => {
    // const navigate = useNavigate(); // Variable assigned to useNavigate() function to navigate to a selected page
    const { instance, accounts } = useMsal(); // Variable assigned to useMsal() function to use the instance of the MSAL library

    // const [booleanPairs, setBooleanPairs] = useState([]);
    // const [userInfo, setUserInfo] = useState<{ firstName: string, lastName: string, email: string } | null>(null);

    useEffect(() => {
        const handleRedirect = async () => {
          try {
            await instance.initialize();
            const response = await instance.handleRedirectPromise();
            if (response) {
              instance.setActiveAccount(response.account);
            } else {
              const activeAccount = instance.getActiveAccount();
              if (!activeAccount && accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
              }
            }
          } catch (error) {
            console.error("Error handling redirect", error);
          }
        };
    
        handleRedirect();
      }, [accounts, instance]);

    const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        // Check if there is already an active account
        const activeAccount = instance.getActiveAccount();
        if (activeAccount) {
            console.log("User already logged in:", activeAccount);
            window.location.href = `${window.location.origin}/AboutUs/Login/Smart-Recruitment`; // Use window.location.href for navigation
            return;
        }

        // Proceed with loginPopup if no active account
        instance.loginPopup(loginRequest).then((response) => {
            if (response) {
                const idToken = response.idTokenClaims as any;
                const firstName = idToken.given_name || idToken.name?.split(' ')[0];
                const lastName = idToken.family_name || idToken.name?.split(' ').slice(1).join(' ');
                const email = idToken.email;

                console.log(`User logged in: ${firstName} ${lastName}, Email: ${email}`);

                // Set the active account for future token acquisition
                instance.setActiveAccount(response.account);

                // Navigate to the desired page
                window.location.href = `${window.location.origin}/AboutUs/Login/Smart-Recruitment`; // Use window.location.href for navigation
            }
        }).catch((error) => {
            console.error("Was not able to log in", error);
        });
    };

    // const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
        
    //     // Check if there is already an active account
    //     const activeAccount = instance.getActiveAccount();
    //     if (activeAccount) {
    //       console.log("User already logged in:", activeAccount);
    //     //   navigate("/AboutUs/Login/Smart-Recruitment");
    //       navigate("Smart-Recruitment");
    //       return;
    //     }
      
    //     // Proceed with loginPopup if no active account
    //     instance.loginPopup(loginRequest).then((response) => {
    //       if (response) {
    //         const idToken = response.idTokenClaims as any;
    //         const firstName = idToken.given_name || idToken.name?.split(' ')[0];
    //         const lastName = idToken.family_name || idToken.name?.split(' ').slice(1).join(' ');
    //         const email = idToken.email;
      
    //         console.log(`User logged in: ${firstName} ${lastName}, Email: ${email}`);
      
    //         // Set the active account for future token acquisition
    //         instance.setActiveAccount(response.account);
      
    //         // Navigate to the desired page
    //         navigate("Smart-Recruitment");
    //       }
    //     }).catch((error) => {
    //       console.error("Was not able to log in", error);
    //     });
    // };

    
      

    // const handleLogin = (e: React.FormEvent<HTMLFormElement>) => { //Function to handle the login process
    //     e.preventDefault();
    //     instance.loginPopup(loginRequest).then((response) => { // Provides a popup window for the user to log in
    //         // console.log(response);

    //         const idToken = response.idTokenClaims as any;
    //         const firstName = idToken.given_name || idToken.name?.split(' ')[0];
    //         const lastName = idToken.family_name || idToken.name?.split(' ').slice(1).join(' ');
    //         const email = idToken.email;

    //         console.log(`User logged in: ${firstName} ${lastName}, Email: ${email}`)

    //         navigate("/AboutUs/Login/Smart-Recruitment") // Navigates to the app page after successful login
    //     }).catch((error) => {
    //         console.log("Was not able to log in", error);   
    //     });
    // }

    return (
        <section className="auth-section">
            <div className="auth-container">
                    
                {/* Logo */}
                <div className="auth-logo">
                    <img src="/assets/images/mzm_logo.png" alt="MZM Technologies" />
                </div>
                    
                {/* Login functionality displayed */}
                <form onSubmit={handleLogin} className="auth-form">
                    <p>Please Login to Your Account</p>

                    {/* Submit button */}
                    <div className="auth-button-container">
                        <button
                            className="auth-button"
                            type="submit"
                        >
                            Log in with Microsoft
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Auth;
