/******************************************************************************************************************************************************************************
 *
 *                          Solution: Smart-Recruitment
 *                          Description: Handles the form for recruiters to input the job title and description
 *                          Created Date: January 22nd, 2025
 *                          Created By: Areeb Khan
 *                          Last Updated Date: January 22nd, 2025
 *                          Last Updated By: Areeb Khan
 *                          Version: 1.0
 *
 ********************************************************************************************************************************************************************************/
//Imported Libraries
import React, {FormEvent} from "react";
import './smartrecruitment.css';

//Interface for the properties of the JobForm function
interface JobFormProps {
    jobTitle: string;
    description: string;
    setJobTitle: (jobTitle: string) => void;
    setDescription: (description: string) => void;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
    onReset: () => void;
    fetchBooleanPairsAgain: () => void;
    // calculateInitialWeightages: () => void;
}

//Function that returns the JobForm component
const JobForm: React.FC<JobFormProps> = ({
    jobTitle,
    description,
    setJobTitle,
    setDescription,
    handleSubmit,
    onReset,
    fetchBooleanPairsAgain,
    // calculateInitialWeightages
}) => {

    // const thehandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     handleSubmit(e);
    //     const initialWeightages = calculateInitialWeightages();
    // }

    return (
        <section className="job-form-section">
            <div className="job-form-container">
                <form onSubmit={handleSubmit}>
                    
                    {/* Job Title */}
                    <div className="mb-4">
                        <label className="job-form-label">Job Title</label>
                        <input
                            type="text"
                            value={jobTitle}
                            onChange={(e) => {
                                setJobTitle(e.target.value)
                                fetchBooleanPairsAgain()
                            }}
                            className="job-form-input"
                            required
                        />
                    </div>
                    
                    {/* Job Description */}
                    <div className="mb-4">
                        <label className="job-form-label">Job Description</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="job-form-textarea"
                            required
                        ></textarea>
                    </div>

                    {/* Submit Button */}
                    <div className="button-group">
                        <button
                            type="submit"
                            className="job-form-button"
                        >
                            Submit
                        </button>
                        <button
                            onClick={onReset}
                            className="reset-button"
                        >
                            Reset
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default JobForm;