/******************************************************************************************************************************************************************************
 *
 *                          Solution: Smart-Recruitment
 *                          Description: Handles the technology requirements component for recruiters to input the technology requirements after clicking the submit button
 *                          Created Date: January 22nd, 2025
 *                          Created By: Areeb Khan
 *                          Last Updated Date: February 4th, 2025
 *                          Last Updated By: Areeb Khan
 *                          Version: 1.0
 *
 ********************************************************************************************************************************************************************************/
//Imported Libraries
import React, {useState, useEffect, ChangeEvent, useRef} from "react";
import { FaPlusCircle, FaMinusCircle, FaSave, FaSearch, FaGripVertical } from "react-icons/fa";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Tooltip} from "react-tooltip"
import './smartrecruitment.css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfigur.ts";
// import { loginRequest } from "../authConfigur";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import { ThreeDots } from 'react-loader-spinner'

//Interface for the technology requirements
interface TechRequirementsProps {
    technologyRequirements: {BOOLEANNAMEVALUEPAIRID?: number; BOOLEANNAME: string; BOOLEANVALUE: string; weightage?: string; isEditable?: boolean; index: number;}[];
    handleTechnologyChange: (index: number, field: string, value: string | number) => void;
    handleAddTechnology: () => void;
    handleRemoveTechnology: (index: number) => void;
    fetchBooleanPairsAgain: () => void;
    handleReorder: (newTechRequirements: {BOOLEANNAMEVALUEPAIRID?: number; BOOLEANNAME: string; BOOLEANVALUE: string; weightage?: string; isEditable?: boolean; index: number;}[]) => void;
    // roles: string[];
    // weightages: {value: number, isChanged: boolean}[]
    // setWeightages: (weightages: {value: number, isChanged: boolean}[]) => void;
    // calculateInitialWeightages: () => { value: number, isChanged: boolean }[];
}

interface ApiResponse {
  results?: any;
  error?: string;
}

const TechRequirements: React.FC<TechRequirementsProps> = ({ //Function to handle the technology requirements(adding, removing, updating or saving)
    technologyRequirements, 
    handleTechnologyChange, 
    handleAddTechnology, 
    handleRemoveTechnology,
    fetchBooleanPairsAgain,
    handleReorder,
    // roles,
    // weightages,
    // setWeightages,
    // calculateInitialWeightages
}) => {
    const [weightages, setWeightages] = useState<{value: number, isChanged: boolean}[]>([]); // State for the weightages
    const [previousWeightages, setPreviousWeightages] = useState<{value: number, isChanged: boolean}[]>([]);
    const [isAddingRow, setIsAddingRow] = useState<boolean>(false); // State for the adding row status
    const [inputValues, setInputValues] = useState<string[]>([]); // State for the input values
    const [shouldCalculateInitialWeightages, setShouldCalculateInitialWeightages] = useState<boolean>(true);
    const [shouldAdjustWeightages, setShouldAdjustWeightages] = useState<boolean>(false)
    // const [swappingIndex, setSwappingIndex] = useState<number | null>(null);
    const [searchVisible, setSearchVisible] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState<string>("");
    const [searchResult, setSearchResult] = useState<{BOOLEANNAMEVALUEPAIRID?: number; BOOLEANNAME: string; BOOLEANVALUE: string; weightage?: string; isEditable?: boolean; index: number;} | null>(null);
    const [searchResults, setSearchResults] = useState<any[]>([]); // State for the search results
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [numCandidates, setNumCandidates] = useState<number>(10);
    const initialWeightagesCalculated = useRef(false);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const resultsPerPage = 10; // Number of results to display per page
    
    const { instance, accounts } = useMsal();

      // useEffect(() => {
      //   const handleRedirect = async () => {
      //     try {
      //       await instance.initialize();
      //       const response = await instance.handleRedirectPromise();
      //       if (response) {
      //         instance.setActiveAccount(response.account);
      //       } else {
      //         const activeAccount = instance.getActiveAccount();
      //         if (!activeAccount && accounts.length > 0) {
      //           instance.setActiveAccount(accounts[0]);
      //         }
      //       }
      //     } catch (error) {
      //       console.error("Error handling redirect", error);
      //     }
      //   };
    
      //   handleRedirect();
      // }, [accounts, instance]);

      useEffect(() => {
        if (technologyRequirements.length > 0 && weightages.length === 0) {
          const initialWeightages = technologyRequirements.map((tech) => ({
            value: tech.weightage ? parseFloat(tech.weightage) : 0,
            isChanged: false,
          }));
          setWeightages(initialWeightages);
        }
      }, [technologyRequirements]);
    
    useEffect(() => {
      if (shouldCalculateInitialWeightages) {
        const hasZeroWeightage = weightages.some(weightage => weightage.value === 0);
        if(!hasZeroWeightage){
          calculateInitialWeightages();
        }
      }
    }, [technologyRequirements, shouldCalculateInitialWeightages]);

    useEffect(() => {
      if (shouldAdjustWeightages && currentIndex !== null) {
        adjustWeightages();
      }
    }, [shouldAdjustWeightages, currentIndex]);
    
    const adjustWeightages = async () => {
      // await timeout(2000); // Introduce a delay before adjusting weightages

      console.log("Adjusting weightages...");
      const totalWeightage = 100;
      console.log("Weightages: ", weightages);
      const changedWeightages = weightages.filter(weightage => weightage.isChanged);
      const unchangedWeightages = weightages.filter(weightage => !weightage.isChanged);

      console.log("Changed weightages:", changedWeightages);
      console.log("Unchanged weightages:", unchangedWeightages);

      const remainingWeightage = totalWeightage - changedWeightages.reduce((sum, weightage) => sum + weightage.value, 0);
      console.log("Remaining weightage:", remainingWeightage);

      let newWeightageValue = unchangedWeightages.length > 0 ? parseFloat((remainingWeightage / unchangedWeightages.length).toFixed(2)) : 0;
      console.log("New weightage value for unchanged weightages:", newWeightageValue);

      setShouldCalculateInitialWeightages(false);

      setWeightages(prevWeightages => {
          let newWeightages = prevWeightages.map((weightage, index) => {
              if (!weightage.isChanged) {
                  return { value: Math.max(0, newWeightageValue[index] || newWeightageValue), isChanged: false };
              }
              return { ...weightage, isChanged: true }; // Ensure the isChanged flag remains true for changed weightages
          });


          const totalNewWeightage = newWeightages.reduce((sum, weightage) => sum + weightage.value, 0);
          const totalFlaggedWeightage = newWeightages.filter(weightage => weightage.isChanged).reduce((sum, weightage) => sum + weightage.value, 0);

          const allFlagsSet = newWeightages.every(weightage => weightage.isChanged);
          // if(allFlagsSet){
          //   window.confirm("All fields have been adjusted, from now on only one row's weightage can be adjusted at a time.")
          // }

          if (allFlagsSet) {
              console.log("All flags are set. Starting reallocation for all flags set...");
              console.log("Initial weightages:", newWeightages);
              console.log("Remaining excess:", totalNewWeightage - totalWeightage);

              let remainingExcess = totalNewWeightage - totalWeightage;
              let reallocatedWeightage = remainingExcess / (newWeightages.length - 1);
              let newRemainingExcess = 0;

              newWeightages = newWeightages.map((weightage, index) => {
                  if (index !== currentIndex) {
                      let newValue = weightage.value - reallocatedWeightage;
                      if (newValue < 0) {
                          newRemainingExcess -= newValue; // Add the negative value to new remaining excess
                          newValue = 0;
                      }
                      console.log(`Adjusting weightage at index ${index}: newValue = ${newValue.toFixed(2)}`);
                      return { ...weightage, value: parseFloat(newValue.toFixed(2)) };
                  }
                  return weightage;
              });

              console.log("New weightages after reallocation for all flags set:", newWeightages);
              console.log("New remaining excess:", newRemainingExcess);

              setInputValues(newWeightages.map(weightage => weightage.value.toString()));
              newWeightages.forEach((weightage, idx) => handleTechnologyChange(idx, 'weightage', weightage.value));

              return newWeightages;
          }
          if (totalFlaggedWeightage > totalWeightage) {
              const proceed = window.confirm("The total weightage exceeds 100. Do you want to proceed with adjusting the weightages?");
              if (proceed) {
                  const excessWeightage = totalNewWeightage - totalWeightage;
                  let remainingExcess = excessWeightage;

                  const reallocateWeightage = (weightages, remainingExcess, currentIndex, bypassFlag = false) => {
                      console.log("Starting reallocation...");
                      console.log("Initial weightages:", weightages);
                      console.log("Remaining excess:", remainingExcess);
                      console.log("Current index:", currentIndex);

                      let reallocatedWeightage = remainingExcess / (weightages.length - 1);
                      let newRemainingExcess = 0;

                      const newWeightages = weightages.map((weightage, idx) => {
                          if (idx !== currentIndex || bypassFlag) {
                              let newValue = weightage.value - reallocatedWeightage;
                              if (newValue < 0) {
                                  newRemainingExcess -= newValue; // Add the negative value to new remaining excess
                                  newValue = 0;
                              }
                              console.log(`Adjusting weightage at index ${idx}: newValue = ${newValue.toFixed(2)}`);
                              return { ...weightage, value: parseFloat(newValue.toFixed(2)) };
                          }
                          return weightage;
                      });

                      console.log("New weightages after reallocation:", newWeightages);
                      console.log("New remaining excess:", newRemainingExcess);

                      if (newRemainingExcess > 0 && newRemainingExcess !== remainingExcess) {
                          const nonZeroWeightages = newWeightages.filter((weightage, idx) => (idx !== currentIndex || bypassFlag) && weightage.value > 0);
                          if (nonZeroWeightages.length > 0) {
                              const newReallocatedWeightage = newRemainingExcess / nonZeroWeightages.length;
                              newRemainingExcess = 0;

                              const finalWeightages = newWeightages.map((weightage, idx) => {
                                  if ((idx !== currentIndex || bypassFlag) && weightage.value > 0) {
                                      let newValue = weightage.value - newReallocatedWeightage;
                                      if (newValue < 0) {
                                          newRemainingExcess += newValue; // Add the negative value to new remaining excess
                                          newValue = 0;
                                      }
                                      console.log(`Final adjusting weightage at index ${idx}: newValue = ${newValue.toFixed(2)}`);
                                      return { ...weightage, value: parseFloat(newValue.toFixed(2)) };
                                  }
                                  return weightage;
                              });

                              console.log("Final weightages after reallocation:", finalWeightages);
                              console.log("Final remaining excess:", newRemainingExcess);

                              if (newRemainingExcess > 0 && newRemainingExcess !== remainingExcess) {
                                  return reallocateWeightage(finalWeightages, newRemainingExcess, currentIndex, bypassFlag);
                              }

                              return finalWeightages;
                          }
                      }

                      return newWeightages;
                  };

                newWeightages = reallocateWeightage(newWeightages, remainingExcess, currentIndex);
            } else{
                newWeightages = [...previousWeightages];
                setWeightages(newWeightages);
                setInputValues(newWeightages.map(weightage => weightage.value.toString()));
                return newWeightages;
            }
          }

          console.log("New weightages:", newWeightages);
          console.log("Weightages after setting in adjustWeightages:", newWeightages);

          setInputValues(newWeightages.map(weightage => weightage.value.toString()));
          newWeightages.forEach((weightage, idx) => handleTechnologyChange(idx, 'weightage', weightage.value));

          return newWeightages;
      });
      setShouldAdjustWeightages(false); // Reset the flag
    };
      
      const calculateInitialWeightages = () => {
        const totalWeightage = 100;
        let initialWeightages = technologyRequirements.map((tech, index) => {
          const existingWeightage = weightages[index];
          return {
            value: tech.weightage ? parseFloat(tech.weightage) : parseFloat((totalWeightage / technologyRequirements.length).toFixed(2)),
            isChanged: existingWeightage ? existingWeightage.isChanged : false,
          };
        });
    
        // Preserve weightages that have been set to 0 or changed
        initialWeightages = initialWeightages.map((weightage, index) => {
          if (weightages[index] && weightages[index].isChanged) {
            return weightages[index];
          }
          return weightage;
        });
    
        setWeightages(initialWeightages);
        setInputValues(initialWeightages.map(weightage => weightage.value.toString()));
      };
    
      const adjustWeightagesOnAdd = () => {
        // const hasChangedWeightage = weightages.some(weightage => weightage.isChanged);
        // if(hasChangedWeightage){
        //   setShouldAdjustWeightages(true);
        //   console.log("Weightages have been adjusted: ", hasChangedWeightage, weightages)
        // } else{
          // setShouldAdjustWeightages(false)
          const totalWeightage = 100;
          let initialWeightages = technologyRequirements.map((tech) =>
            tech.weightage || (totalWeightage / technologyRequirements.length).toFixed(2)
          );
      
          const weightageSum = initialWeightages.reduce((sum, val) => sum + parseFloat(val), 0);
          if (weightageSum !== totalWeightage) {
            const lastIdx = initialWeightages.length - 1;
            initialWeightages[lastIdx] = (parseFloat(initialWeightages[lastIdx]) + totalWeightage - weightageSum).toFixed(2);
          }
          setWeightages(initialWeightages.map(weightage => ({ value: parseFloat(weightage), isChanged: false })));
          setInputValues(initialWeightages);
          setIsAddingRow(false);
        // }
        // setShouldAdjustWeightages(true);
      };
    
    const adjustWeightagesOnRemove = () => {  
      // const hasChangedWeightage = weightages.some(weightage => weightage.isChanged);
      // if(hasChangedWeightage){
      //   setShouldAdjustWeightages(true);
      //   console.log("Weightages have been adjusted: ", hasChangedWeightage, weightages)
      // } else{
        // setShouldAdjustWeightages(false)
        const totalWeightage = 100;
        let initialWeightages = technologyRequirements.map((tech) =>
          tech.weightage || (totalWeightage / technologyRequirements.length).toFixed(2)
        );
        setWeightages(initialWeightages.map(weightage => ({ value: parseFloat(weightage), isChanged: false })));
        setInputValues(initialWeightages);
        
      // setShouldAdjustWeightages(true)
      // }
    };

    const handleInputChange = async (index: number, value: string) => { // Function to handle the input change
      console.log(`Received index: ${index} (type: ${typeof index})`);
      console.log(`Received value: ${value} (type: ${typeof value})`);
  
      if (index < 0 || index >= weightages.length) {
          console.error(`Invalid index: ${index}`);
          return;
      }
      
      setCurrentIndex(index);
      setShouldCalculateInitialWeightages(false);

      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);

      setPreviousWeightages(weightages)
  
      setWeightages(prevWeightages => {
        let newWeightages = [...prevWeightages];
        const newValue = parseFloat(value) || 0;

        if (newValue < 0) {
            window.confirm("Negative numbers are not allowed. The weightage has been reset to its previous state.");
            newWeightages = [...previousWeightages];
            setWeightages(newWeightages);
            setInputValues(newWeightages.map(weightage => weightage.value.toString()));
            return newWeightages;
        }

        if (newValue > 100) {
          window.confirm("Numbers greater than 100 are not allowed. The weightage has been reset to 100.");
          newWeightages[index] = { value: 100, isChanged: true };
          setWeightages(newWeightages);
          setInputValues(newWeightages.map(weightage => weightage.value.toString()));
          handleTechnologyChange(index, 'weightage', 100);
          return newWeightages;
        }

        newWeightages[index] = { value: newValue, isChanged: true };

        // const trueFlagsCount = newWeightages.filter(weightage => weightage.isChanged).length;
        // if (trueFlagsCount === newWeightages.length) {
        //   newWeightages = newWeightages.map((weightage, idx) => {
        //       if (idx === index) {
        //           return { ...weightage, isChanged: true };
        //       }
        //       return { ...weightage, isChanged: false };
        //   });
        // }

        console.log("Updated weightages:", newWeightages);
        console.log("Weightages after setting in handleInputChange:", weightages); 

        handleTechnologyChange(index, 'weightage', newWeightages[index].value);
        console.log(`Passing index to handleWeightageInputChange: ${index} (type: ${typeof index})`);

        return newWeightages;
      });

      setShouldAdjustWeightages(true);
    };

    const handleUpdate = async (index: number) => { // Function to handle the update process
        const tech = technologyRequirements[index];
        console.log("Updating technology requirement:", tech);
        
        // Warns the user before updating/saving the technology requirement
        const confirmUpdate = window.confirm("Are you sure you want to update this technology requirement?");
        if (!confirmUpdate) {
          return;
        }
    
        try{
          const activeAccount = instance.getActiveAccount()
          if(!instance.getActiveAccount()){
            throw new Error(`${process.env.REACT_APP_ERROR_MESSAGE_NOACTIVEACCOUNT}`)
          }
          
          await instance.initialize();
          
          const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: activeAccount || undefined,
          
          });
          const accessToken = tokenResponse.accessToken;
          const idToken = tokenResponse.idToken;
          console.log(`${process.env.REACT_APP_MESSAGE_ACCESSTOKEN}`);
          console.log(`${process.env.REACT_APP_MESSAGE_IDTOKEN}`)
          
          const existingResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/booleanPairs/`, {
            headers:{
              Authorization: `Bearer ${accessToken}`,
              Authentication: `Bearer ${idToken}`
            }
          }); // Fetches the existing response from the backend server
          const existingData = await existingResponse.json(); // Parses the JSON data and assigned to a variable
          const isDuplicate = existingData.some((entry: {BOOLEANNAME: string; BOOLEANVALUE: string; BOOLEANNAMEVALUEPAIRID?: number}) =>
            entry.BOOLEANNAME.toLowerCase() === tech.BOOLEANNAME.toLowerCase() &&
            entry.BOOLEANVALUE.toLowerCase() === tech.BOOLEANVALUE.toLowerCase()
          ); // Checks if a duplicate technology requirement is being saved into the database

          if(isDuplicate){ // Checks if a duplicate technology requirement is being saved into the database
            console.error(`${process.env.REACT_APP_ERROR_MESSAGE_DUPLICATE_RECORD}`, tech);
            toast.error(`${process.env.REACT_APP_ERROR_MESSAGE_DUPLICATE_RECORD}`);
            return;
          }

          if(tech.BOOLEANNAMEVALUEPAIRID){ // Checks if the technology requirement exists
            // Updates the technology requirement
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/booleanPairs/${tech.BOOLEANNAMEVALUEPAIRID}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`, // Access token is used for authorization
                Authentication: `Bearer ${idToken}`
              },
              body: JSON.stringify({
                BOOLEANNAME: tech.BOOLEANNAME,
                BOOLEANVALUE: tech.BOOLEANVALUE
              })
            });
            if (response.ok) {
              const updatedTech = await response.json();
              console.log(`${process.env.REACT_APP_ERROR_MESSAGE_RECORD_UPDATESUCCESSFUL}`, updatedTech);
              toast.success(`${process.env.REACT_APP_ERROR_MESSAGE_RECORD_UPDATESUCCESSFUL}`); // Notifies the user that the technology requirement has been updated using the toastify library
              fetchBooleanPairsAgain(); // Fetches the boolean pairs again
            } else {
              console.error(`${process.env.REACT_APP_ERROR_MESSAGE_RECORD_UPDATEFAILURE}`);
              toast.error(`${process.env.REACT_APP_ERROR_MESSAGE_RECORD_UPDATEFAILURE}`);
            }
          } else { // Adds a new technology requirement to the database
              const hightestId = existingData.reduce((maxId: number, entry: {BOOLEANNAMEVALUEPAIRID?: number}) => Math.max(maxId, entry.BOOLEANNAMEVALUEPAIRID ?? 0), 0); // Calculates the id of the new technology requirement
              const newId = hightestId + 1; // Assigns the new id to the new technology requirement 

              // Adds the new technology requirement
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/booleanPairs/`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`, // Access token is used for authorization
                    Authentication: `Bearer ${idToken}`
                  },
                  body: JSON.stringify({
                    BOOLEANNAMEVALUEPAIRID: newId,
                    BOOLEANNAME: tech.BOOLEANNAME,
                    BOOLEANVALUE: tech.BOOLEANVALUE
                  })
                });
                if(response.ok){
                  const newTech = await response.json();
                  console.log("Added successfully: ", newTech);
                  toast.success("Added successfully");
                  fetchBooleanPairsAgain(); // Fetches the boolean pairs again
                } else {  
                  console.error("Failed to add");
                  toast.error("Failed to add");
                }
            }
        } catch (error) {
          console.error("Error updating technology requirement: ", error);
          toast.error("Error updating technology requirement");
        }
    }
    
    const thehandleAddTechnology = () => { // Function to handle the adding of a technology requirement
        handleAddTechnology();
        setIsAddingRow(true);
        adjustWeightagesOnAdd();
        calculateInitialWeightages();
        setShouldCalculateInitialWeightages(true);
        // setTechRequirementsState([...techRequirementsState, {BOOLEANNAME: "", BOOLEANVALUE: "", isEditable: true, index: techRequirementsState.length}]);
    };

    const thehandleRemoveTechnology = (index: number) => {
      handleRemoveTechnology(index);
      adjustWeightagesOnRemove();
      calculateInitialWeightages();
      setShouldCalculateInitialWeightages(true);
  };

    const formatTooltipContent = (content: string) => { // Function to format the tooltip content
      const regex = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/g;
      return content.replace(regex, "<br>");
    };

    const handleDragEnd = (result) => {
      if (!result.destination) return; // If the item is dropped outside the list, just return

      const newTechRequirements = Array.from(technologyRequirements); // Copy the array and assign it to a temporary one
      const [movedItem] = newTechRequirements.splice(result.source.index, 1); // Remove the item from the source index
      newTechRequirements.splice(result.destination.index, 0, movedItem); // Insert the item at the destination index
  
      // Update the index of each item to ensure they are consecutive
      newTechRequirements.forEach((tech, idx) => tech.index = idx);
  
      const newWeightages = Array.from(weightages); // Copy the weightages array
      const [movedWeightage] = newWeightages.splice(result.source.index, 1); // Remove the weightage from the source index
      newWeightages.splice(result.destination.index, 0, movedWeightage); // Insert the weightage at the destination index
  
      handleReorder(newTechRequirements);
      setWeightages(newWeightages);
    };

    const handleSearch = async () => {
      try{
        const activeAccount = instance.getActiveAccount()
        if(!instance.getActiveAccount()){
          throw new Error(`${process.env.REACT_APP_ERROR_MESSAGE_NOACTIVEACCOUNT}`)
        }
        await instance.initialize();
        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: activeAccount || undefined,
        
        });
        const accessToken = tokenResponse.accessToken;
        const idToken = tokenResponse.idToken;

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/booleanPairs`, {
          headers: {
              Authorization: `Bearer ${accessToken}`,
              Authentication: `Bearer ${idToken}`,
          },
        });

        const data = await response.json();
        const result = data.find((entry: { BOOLEANNAME: string }) =>
            entry.BOOLEANNAME.toLowerCase() === searchInput.toLowerCase()
        );

        if (result) {
            setSearchResult(result);
        } else {
            toast.error("Boolean name not found");
        }
      } catch (error) {
        console.error("Error searching boolean name:", error);
        toast.error("Error searching boolean name");
      }
    }

    const handleAddSearchedTechnology = () => {
      if(searchResult){
        const newTechRequirements = [...technologyRequirements,
          {
            BOOLEANNAME: searchResult.BOOLEANNAME,
            BOOLEANVALUE: searchResult.BOOLEANVALUE,
            isEditable: false,
            index: technologyRequirements.length
          }
        ];
        handleReorder(newTechRequirements);
        setSearchVisible(false);
        setSearchInput("");
        setSearchResult(null);
      }
    }

    const handleCancelSearch = () => {
      setSearchVisible(false);
      setSearchInput("");
      setSearchResult(null)
    }

    const sendWeightages = async () => {
      setLoading(true);
      const booleanQueryArray = technologyRequirements.map((req, index) => `"${req.BOOLEANNAME}", "${weightages[index].value.toFixed(2)}"`);
      const booleanQueryString = booleanQueryArray.join(' AND ');
      // console.log(booleanQueryString);
      try {
        const response = await fetch(`${process.env.REACT_APP_PYTHON_ROUTE}/search-resumes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query: booleanQueryString, n_results: numCandidates})
        });
        const data: ApiResponse = await response.json();
        if (data.results) {
            setSearchResults(data.results);
        } else if (data.error) {
            console.error("Error adding numbers: ", data.error);
            setSearchResults([]);
        }
      } catch (error) {
          console.error("Error fetching data: ", error);
          setSearchResults([]);
      } finally{
        setLoading(false);
      }
    };

    const handlePageChange = (newPage: number) => {
      setCurrentPage(newPage);
    };
    const indexOfLastResult = currentPage * resultsPerPage;
    const indexOfFirstResult = indexOfLastResult - resultsPerPage;
    const currentResults = searchResults.slice(indexOfFirstResult, indexOfLastResult);


    return (
      <div className="tech-requirements-container">
        <div className="tech-requirements-heading">
          <h2 className="tech-requirements-title">Search Requirements</h2>
          {/* <button
            // onClick={adjustWeightages}
            className="tech-requirements-button"
          >
            Adjust Weightages
          </button> */}
        </div>  
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="tech-requirements-list">
              {(provided) => (
                <div
                  className="tech-requirements-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {technologyRequirements.map((tech, index) => (
                    <Draggable key={tech.BOOLEANNAMEVALUEPAIRID || `tech-${index}`} draggableId={tech.BOOLEANNAMEVALUEPAIRID ? tech.BOOLEANNAMEVALUEPAIRID.toString() : `tech-${index}`} index={index}>
                      {(provided, snapshot) => ( // provided contains properties to apply to a draggable element, snap shot contains the state of the draggable element
                        <div
                          ref={provided.innerRef} // Reference to the draggable element
                          {...provided.draggableProps} // Dragging properties
                          // {...provided.dragHandleProps} // Dragging handle properties
                          className={`tech-requirements-item ${snapshot.isDragging ? 'dragging' : ''}`} // Class name for the draggable element
                        >
                        <div className="left-side-buttons">
                          <div {...provided.dragHandleProps} className="drag-handle">
                            <FaGripVertical/>
                          </div>
                          <FaMinusCircle onClick={() => thehandleRemoveTechnology(index)} className="left-button" title="Remove Technology" />
                        </div>
                            
                          {tech.isEditable ? (
                            <input
                              type="text"
                              value={tech.BOOLEANNAME}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => handleTechnologyChange(index, "BOOLEANNAME", e.target.value)}
                              placeholder="Boolean Name"
                              className="tech-requirements-input"
                            />
                          ) : (
                            <span className="tech-requirements-span">
                              {tech.BOOLEANNAME}
                            </span>
                          )}
                          <input
                            type="text"
                            value={tech.BOOLEANVALUE}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleTechnologyChange(index, "BOOLEANVALUE", e.target.value)}
                            placeholder="Boolean Value"
                            className="tech-requirements-value"
                            data-tooltip-id={`tooltip-${index}`}
                            data-tooltip-content={formatTooltipContent(tech.BOOLEANVALUE)}
                          />
                          <Tooltip id={`tooltip-${index}`} place="top" variant="dark" />
                          <input
                            type="number"
                            value={weightages[index] ? weightages[index].value : 0}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
                            placeholder="Weightage"
                            className={`tech-requirements-weightage ${weightages[index]?.isChanged ? 'changed': ''}`}
                          />
                          <div className="tech-requirements-buttons">
                            {/* {roles.includes("Boolean_Modifier") && ( */}
                              <FaSave onClick={() => handleUpdate(index)} className="icon-button" title="Save or Overwrite to Database" />
                            {/* )} */}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="tech-requirements-add">
            {/* Add button */}
            <FaPlusCircle onClick={thehandleAddTechnology} className="icon-button" title="Add Technology"/>
            <FaSearch onClick={() => setSearchVisible(true)} className="icon-button" title="Search Technology" />
          </div>
          {searchVisible && (
            <div className="search-container">
              <input
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Search Boolean Name"
                className="search-input"
              />
                <button onClick={handleSearch} className="search-button">Search</button>
                {searchResult && (
                  <>
                  <div className="search-result">
                      {/* <p>Boolean Name: {searchResult.BOOLEANNAME}</p> */}
                    <input
                      type="text"
                      value={searchResult.BOOLEANVALUE}
                      readOnly
                      className="b-value"
                      data-tooltip-id="search-result-tooltip"
                      data-tooltip-content={searchResult.BOOLEANVALUE}
                    />
                    <Tooltip id="search-result-tooltip" place="top" variant="dark" className="custom-tooltip" />
                  </div>
                    <div className="addcancel-buttons">
                      <button onClick={handleAddSearchedTechnology} className="add-button">Add</button>
                      <button onClick={handleCancelSearch} className="cancel-button">Cancel</button>
                    </div>
                  </>
                )}
            </div>
          )}
          {process.env.REACT_APP_SHOW_CANDIDATES_SECTION === 'true' && (
            <div className="tech-requirements-search">
              <h3>Best Suited Candidates</h3>
              <div className="tech-requirements-retrieve"> 
                <div className="num-candidates-input">
                  <label htmlFor="num-candidates">Top:</label>
                  <input
                    type="number"
                    id="num-candidates"
                    value={numCandidates}
                    onChange={(e) => setNumCandidates(parseInt(e.target.value))}
                    min="1"
                  />
                </div> 
                <button onClick={sendWeightages} className="weightage-button"> Find Candidates </button>
              </div>
            </div>
          )}
          {loading ? (
            <div className="loading-spinner">
              <ThreeDots color="white" height={60} width={60} />
            </div>
          ) : (
            <div className="search-results">
              {searchResults.length > 0 ? (
                <div>
                  <ul>
                    {currentResults.map((output, index) => (
                      <div key={index}>
                        <h3>{output.candidate_name}</h3>
                        <div className="candidates">
                          <h2>Rank {output.rank}</h2>
                          <li className="search-result-item">
                            {/* <p className="search-result-detail">Rank: {output.rank}</p> */}
                            <p className="search-result-detail">Resume File: {output.filename}</p>
                            {/* <p className="search-result-detail">Candidate Name: {output.candidate_name}</p> */}
                            <p className="search-result-detail">Skills: {output.skills}</p>
                            <p className="search-result-detail">Experience Years: {output.experience_years}</p>
                            <p className="search-result-detail">Education: {output.education}</p>
                            <p className="search-result-detail">Job Title: {output.job_title}</p>
                            <p className="search-result-detail">Contact Email: {output.contact_email}</p>
                            <p className="search-result-detail">Contact Phone: {output.contact_phone}</p>
                            <p className="search-result-detail">Relevance Score: {output.relevance_score}</p>
                          </li>
                        </div>
                      </div>
                    ))}
                  </ul>
                  <div className="pagination">
                    {Array.from({ length: Math.ceil(searchResults.length / resultsPerPage) }, (_, i) => (
                      <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        className={`pagination-button ${currentPage === i + 1 ? 'active' : ''}`}
                      > 
                        {i + 1}
                      </button>
                    ))}
                  </div>
                </div>
              ) : ( 
                <p></p>
              )}
            </div>
          )}
        <ToastContainer/>
      </div>
  );
};

export default TechRequirements;
